.wrapper {
    display: grid;
    grid-template-columns: 178px 1fr;
  }
.text_wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5fr;
}

.title_wrapper{
    margin-top: 40px;
    margin-left: 20px;
}
.links_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px;
    margin: 0px 10%;
}

.links_wrapper div{
    width: 80%;
    border-radius: 20px;
    height: 180px;
    margin: 20px 3%;
   
}

.links_wrapper div div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h2{
    color: black;
    font-size: xx-large;
    margin-bottom: 10px;
}

.ingresoLink{
    background-color: #00BFA6;
    display: flex;
    flex-direction: column;
}

.gastos_link{
    background-color: #F47579;
    display: flex;
    flex-direction: column;
}

.ahorros_link{
    background-color: #FACB68;
    display: flex;
    flex-direction: column;
}

.detalles_link{
    background-color: #65B5E9;
}

.novedades_link{
    background-color: #857BFB;
    
}

.expand_columns{
    grid-column: span 2 / auto;
}