.nove_h1{
    margin-top:0px;
    padding: 20px;
}

.news_wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,minMax(400px,1fr));
    gap: 3rem;
    justify-items: center;
    
}
.img_news{
    width: 400px;
    height: 300px;
    border-radius:20px 20px 0 0 ;
}
.cointainer_news{
    height: 100vh;
    overflow: auto;

}

.newsCard{
    width: 400px;
    text-align: center;
    background-color: gray;
    border-radius: 20px ;
}

.newsCard a h4{
    margin: 10px 0 10px 0;
}