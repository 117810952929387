.wrapperForm {
    color: black
}

.wrapperForm h2{
    color: black
}

.wrapperForm input {
    margin-top: 5px;
    margin-bottom: 5px;
}

.wrapperForm .inputText {
    width: 250px;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid rgb(142, 140, 140);
    border-radius: 20px;
    color: rgb(142, 140, 140);
    background-color: #E3E3E3;
}

.wrapperForm .inputDate {
    width: 100px;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid rgb(142, 140, 140);
    border-radius: 20px;
    color: rgb(142, 140, 140);
    background-color: #E3E3E3;
}

.labelCurrent {
    color: black;
    font-weight: bold;
}

.wrapperForm .inputCurrent {
    width: 10px;
    margin-right: 20px;
}


.wrapperForm .buttonDelete {
    padding: 12px;
    margin-right: 20px;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: #e30808;
    transition: background-color .2s;
    cursor: pointer;
}

.wrapperForm button:hover {
    background-color: rgb(118, 189, 3) ;
}

.divCloseButton {
    display: flex;
    justify-content: end;
}

.closeButton {
    width: 30px;
    height: 30px;
    border: none;
    background-color: red;
    cursor: pointer;
}

.wrapperAllCurrents {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 5px;
}

.wrapperCurrentsA {
    padding: 10px;
    margin-right: 50px;
}

.wrapperCurrentsB {
    padding: 10px;
}
.buttonForm{
    padding: 5px;
    border-radius: 5px;
}