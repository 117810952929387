.wrapperPag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
}

.wrapperPag button {
    width: 70px;
    height: 40px;
    font-size: 15px;
    font-weight:lighter;
    border: none;
    border-radius: 15px;
    color: rgb(74, 70, 70);
    background-color: #333333;;
    transition: background-color .2s;
    cursor: pointer;
}

.wrapperPag button:hover {
    background-color: rgb(118, 189, 3) ;
    color: aliceblue;
}

.wrapperPag .active {
    color: rgb(118, 189, 3);
    border: 1px solid rgb(118, 189, 3);
    background-color:#555555;
}

.disabledPrev {
    opacity: 0.3;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.disabledNext {
    opacity: 0.3;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}