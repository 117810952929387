.wrapper {
    position: absolute;
    top: calc(20vh - 40px);
    left: calc(20vw - 40px);
    width: calc(60vw - 40px);
    height: calc(60vh - 40px);
    border-radius: 10px;
    padding: 40px;
    background-color: #1d1d1d;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 2fr 1fr;
    justify-items: center;
    align-items: center;
    z-index: 5;
}
.imageInput {
   width: min-content;
   background-color: #090909;
   color: #a11414;
   display: flex;
   justify-content: center;
   align-items: center;
}
.acceptButton {
   border-radius: 10px;
   width: min-content;
   padding: 5px 10px;
   background-color: none;
   cursor: pointer;
   text-transform: uppercase;
   background-color:#696969;
   color: white;
}

.closeButton {
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

 .topAvatarEditorContainer {
    width: 100%;
    height: 100%;
    display:flex;
    justify-items: left;
 }

 .bottomAvatarEditorContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
 }

 .dropZone{
    align-self: center;
    justify-self: center;
    text-align: center;
    width: 200px;
    height: 200px;
    border: 3px dashed #eeeeee;
    border-radius: 4px;
    color: #bdbdbd;      
    display: flex;
    justify-content: center;
    align-items: center;
 }