.wrapperAllIngreso{
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.wrapperText {
    width: 40%;
    padding:60px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapperText h1 {
    padding: 0px 10px 10px 50px;
    font-weight: lighter;
    font-size: 3.0em;
    color: #D2D2D2;
}

.wrapperLink {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapperLink p {
    padding: 0px 20px 0px 50px;
    font-weight: lighter;
    font-size: 1.0em;
    color: #00BFA6;
}

.wrapperLink button {
    width: 57px;
    height: 56px;
    display: grid;
    justify-content: center;
    align-content: center;
    position: relative;
    color: white;
    font-size: 40px;
    border: none;
    border-radius: 20px;
    background: #00BFA6;
    cursor: pointer;
    transition: background-color .2s;
}

.wrapperLink button:hover {
    font-weight: bold;
    color: white;
    border-color:rgb(118, 189, 3) ;
    background-color: rgb(118, 189, 3) ;
}

.wrapperImg {
    width: 80%;
    height: 80%;
}

.wrapperImg .img {
    width: 75%;
    padding: 20px;
}

.wrapperImg2 {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:1300px)  {
    .wrapperText{
        width: 100%;
    }
    .wrapperImage {
        width: 100%;
    }
    .wrapperAllIngreso {
        flex-direction: column;
    }
}

@media screen and (max-width:900px)  {
    .wrapperText {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:500px)  {
    .wrapperText {
        font-size: 0.5rem;
    }
}