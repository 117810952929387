
.background {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.wrapperAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
}

.title {
    width: 100%;
    color: #D2D2D2;
}

.title h1 {
    font-weight: lighter;
    font-size: 3rem;
}

.wrapperAllSaves {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.wrapperSave {
    display: flex;
    flex-direction: row;
    width: 1100px;
    height: 130px;
    border-radius: 20px;
    margin: 20px;
    background: linear-gradient(to right, #C6EC59 25% , #333333 88%, #DB0808);
}

.wrapperSave .wrapperSaveLink {
    width: 1070px;
    height: 100%;
    display: flex;
}

.wrapperSaveLink .wrapperName1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #b9e04d;
}

.wrapperName1 .divP {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    padding: 15px 5px 5px 30px;
}

.wrapperName1 .divP p {
    font-weight: lighter;
    font-size: 1.4rem;
    color: white;
}

.wrapperName1 .divH1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    color: white;
    font-size: 1.5rem;
}

.wrapperName1 .divH1 h1 {
    font-weight: 300;
}

.wrapperSaveLink .wrapperTable2{
    display: flex;
    flex-direction: row;
    width: 75%;
    height: 100%;
    position: relative;
    border-radius: 20px;
}

.tableA {
    width: 87%;
    height: 100%;
    border-collapse: collapse;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #333333;
}

.tableA thead th {
    padding: 18px;
    font-weight: lighter;
    font-size: 1.5rem;
    color: #00BFA6;
    border-bottom: 1px solid white;
}

.amount {
    width: 100px;
    height: 30px;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00BFA6;
}

.wrapperGoalB {
    width: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #b9e04d;
    color: white;
}

.divDelete {
    width: 10%;
}

.divDelete button{
    width: 100%;
    height: 100%;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-image: url(../../../assets/delete.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #DB0808;
    cursor: pointer;
}

.divDelete button:hover{
    background-color: rgb(113, 39, 106) ;
}

.wrapperNewSave {
    width: 700px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 20px;
    font-size: large;
    font-weight: bold;
    color: white;
    background-color: #00BFA6;
    cursor: pointer;
    transition: background-color .2s;
}

.wrapperNewSave:hover {
    font-weight: bold;
    color: white;
    border-color:rgb(118, 189, 3) ;
    background-color: rgb(118, 189, 3) ;
}

.addNewSave {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 10px;
    color:#00BFA6;
    background-color: white;
}

.divNothing {
    padding: 20px;
}

.wrapperForm span {
    color: #DB0808;
}
