.wrapper {
    width: 100%;
    height: 50%;
    max-height: 50%;
    background-color: #FCF8F8;
    border: 2px solid black;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: center;
    cursor: default;
    position: relative;
}

.img {
    height: 125px;
    margin: 0px 20px;
    justify-self: center;
    border-radius: 50%;
}

.reviewWrapper {
    display: grid;
    grid-template-rows: 1fr min-content;
    min-height: calc(70%);
    max-height: calc(70%);
    padding: 0px 10px;
    overflow: auto;
}

.reportButton {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
}