.wrapper {
  display: grid;
  grid-template-columns: 178px 1fr;
}
h1{
  margin:10px 0 20px 10px ;
}
.detalles_wrapper{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 7fr;
  height: 100vh;
  overflow: auto;
}

.seccion_wrapper{
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 100vh;
}
.barra_nombres{
  margin-left: 10%;
}
.barra_nombres2{
  margin-left: 60%;
}
.primer_wrapper{
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.totales{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr, 1fr, 1fr;
}
.barra_wrapper{
  border: 2px solid white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 20px 20px 20px;
}
.barra_wrapper div{
  color: white;
  font-size: larger;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-stroke: 0.5px black;
}

.div_popOut{
  height: 70px;
  width: 100%;
  position: absolute;
  display: none;
}

.select_detail{
  display: flex;
  justify-content: center;
}

.hol{
  /* VARIABLES GUARDADAS PARA EL PIE CHARTS
   */
  color: #e27b7b;
  color: #cfc4c4;
  color: #96db99;
  color: #92b0c4;
  color: #d4ca8e;
  color: #7fffd4;
  color: #a864ca;
  color: #316ab6;
  border: 1px black solid;
}

.blocked_wrapper{
  width: 100%;
}

.blocked{
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: .5;
  border-radius: 5px;
}
.blocked:hover{
  opacity: .8;
}

.blocked span{
  margin: 10px;
  color: black;
  font-weight: bolder;
  font-size: larger;
}

.yearLeft{
	padding: 10px;
	border: 1px solid rgb(142, 140, 140) ;
	border-right: 0px;
	border-radius: 20px 0px 0px 20px;
	color: rgb(142, 140, 140);
	background-color: #1f1f1f;
}
.yearCenter{
	padding: 10px;	
	border: 1px solid rgb(142, 140, 140);
	border-left: 0px;
	border-right: 0px;
	color: rgb(142, 140, 140);
	background-color: #1f1f1f;
}
.yearRight{
	padding: 10px;
	border: 1px solid rgb(142, 140, 140);
	border-radius: 0px 20px 20px 0px;
	border-left: 0px;
	color: rgb(142, 140, 140);
	background-color: #1f1f1f;
}


.yearLeft:hover{
	background-color: grey;
	color: black;
}
.yearCenter:hover{
	background-color: grey;
	color: black;
}
.yearRight:hover{
	background-color: grey;
	color: black;
}