.wrapper {
    min-height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.nav {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    background-color: #2E332C;
    padding: 10px 20px;
}

.registerButtonContainer {
    display: flex;
    gap: 5px;
}

.registerButton {
    background-color: lightseagreen;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0px;
    text-overflow: none;
    width: min-content;
}

.registerButton:hover {
    background-color: rgba(32, 178, 171, 0.753);
    color: white;
}

.firstContainer {
    margin: 100px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
}

.landingMan {
   width: 100%;
}

.firstContainerLeftDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10% 0px 10%;
    max-height: calc(100% - 20%);
}

.firstContainer h1, .firstContainer h3 {
    font-weight: 200;
    color: black;
}

.secondContainer {
    background-color: #2E332C;
    margin: 0px 40px 100px 40px;
    min-height: 400px;
    max-height: 400px;
    border-radius: 15px;
    padding: 0px 10%;
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
}
.secondContainerLeftDiv{
    padding: 50px 0px 50px 20px;
    max-height: calc(400px - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.secondContainer h2 {
    font-size: 1.5rem;
} 
.secondContainer h4 {
    color: #545652;
} 
.secondContainerRightDiv {
    height: 100%;
    display: grid;
    grid-template-columns: 15% 1fr 15%;
    align-items: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    max-height: 100%;
}

.reviewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
}

.parentDiv  {
    position: absolute;
    left: calc(25% - 50px);
    bottom: -50vh;
    width: calc(50% - 50px);
    height: calc(50vh - 50px);
    z-index:2;
}

.form {
    position: sticky;
    top: 0px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 25px;
    background-color: rgb(15, 15, 15);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 25% 50% 25%;
    align-items: center;
    justify-items: center;
}

.reportInput {
    padding: 10px;
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    min-height: calc(100% - 20px);
    max-height: calc(100% - 20px);
    border-radius: 4px;
    resize: none;
}

.sendReportButton {
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #20B2AA;
    font-weight: 600;
    letter-spacing: 1.2px;
    cursor: pointer;
    width: max-content;
}

.quitMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}