.wrapper {
    height: 100vh;
    display: grid;
    grid-template-columns: 178px 1fr;
}

.pannelWrapper {
    height: calc(100vh - 5vh);
    background-color: #2D2D2D;  
    padding: 2.5vh 2.5%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.table2 {
    background-color: #444444;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 0 30px 30px 30px;
    cursor: default;
    height: 100%;
}

th {
    padding: 10px 0px;
}

.topPannelWrapper {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}

.graphContainer {
    background-color: #444444;
    width: 100%;
    height: 100%;
    position: relative;
}

.bottomPannelWrapper {
    height: 100%;
    max-height: 100%;
    max-height: 100%;
    overflow: auto;
}