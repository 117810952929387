.wrapper {
    background-color: black;
    margin: 75px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 40px 30px 40px;
    position: relative;
}

.banReportedUser {
    position: absolute;
    background-color: #DB0808;
    cursor: pointer;
    color: #151515;
    border: none;
    border-radius: 25px;
    padding: 0px 15px;
    font-size: larger;
    font-weight: 600;
    width: max-content;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-self: end;
    align-items: center;
    letter-spacing: .5px;
    height: max-content;
    margin-bottom: 10px;
}

.returnButton {
    position: absolute;
    top: -50px;
    right: 20px;
    display: flex;
    width: 125px;
    padding: 5px 15px 5px 10px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #20B2AA;
    font-weight: 600;
}

.closeReportButton {
    position: absolute;
    top: -50px;
    left: 20px;
    cursor: pointer;
    letter-spacing: 2px;
    padding: 10px 15px;
    border-radius: 20px;
    background-color: #22be3a;
    font-weight: 600;
    text-transform: uppercase;
}

.textArea {
    font-size: 1rem;
    margin: 0px;
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    min-height: calc(50% - 20px);
    max-height: calc(50% - 20px);
    padding: 10px;
    resize: none;
    cursor: default;
    background-color: #C5C5C5;
    border-radius: 15px;
    color: #000000;
    align-self: center;
}