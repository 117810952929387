.wrapper {
    height: 100vh;
    display: grid;
    grid-template-columns: 178px 1fr;
    justify-content: center;
    align-items: center;
}

.container {
    margin: 50px;
    background-color: black;
    height: calc(100% - 180px);
    padding: 40px 30px;
    border-radius: 10px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.messageInput {
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
    height: calc(200px - 10px);
    max-height: calc(200px - 10px);
    resize: none;
    border: none;
    background-color: wheat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    padding: 5px;
    border-radius: 4px;
}

.button {
    background-color: lightseagreen;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0px;
    text-overflow: none;
    width: max-content;
    align-self: flex-end;
}

.subjectInput {
    background-color: wheat;
    padding: 5px 10px;
    width: calc(100% - 23px);
    border-radius: 4px;
}

.modal {
    position: absolute;
    top: 25vh;
    left: 25%;
    width: calc(50% - 100px);
    padding: 25px 50px;
    height: calc(50% - 50px);
    border-radius: 10px;
    background-color: rgb(36, 36, 36);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}