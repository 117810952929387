.wrapper{
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
}

.container {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-gap: 20px;
}

.btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
    padding-left: 1rem;
    padding-right: 1rem; 
    color: #1D4ED8; 
    font-weight: 600; 
    border-width: 1px; 
    border-color: #3B82F6;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #3B82F6; 
   color: #ffffff; 
   border-color: transparent; 
  }